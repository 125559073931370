import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import de from 'vuetify/es5/locale/de'

Vue.use(Vuetify)

export default function createVuetify({ primaryColor, accentColor } = {}) {
  return new Vuetify({
    theme: {
      themes: {
        light: {
          primary: primaryColor,
          secondary: '#003c8f',
          accent: accentColor,
          error: '#ff5252',
          info: '#2196f3',
          success: '#4caf50',
          warning: '#ffc107',
        },
      },
    },
    lang: {
      locales: { de },
      current: 'de',
    },
    icons: {
      iconfont: 'fa',
    },
  })
}
