<template>
  <CenterLayout>
    <DefaultCard>
      <template #title>
        {{ $t('resetPassword') }}
      </template>
      <v-form @submit.prevent="resetPassword">
        <v-card-text>
          <p>
            {{ $t('passwortResetProvideEmail') }}
          </p>
          <v-text-field
            v-model="payload.email"
            autofocus
            class="mt-6"
            type="email"
            prepend-icon="fa-envelope"
            :label="$t('emailAddress')"
            :error-messages="errors.email"
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn depressed :to="{ name: 'home' }" color="accent">{{ $t('cancel') }}</v-btn>
          <v-btn depressed type="submit" color="primary" :loading="loading" min-width="120">{{
            $t('requestLink')
          }}</v-btn>
        </v-card-actions>
      </v-form>
    </DefaultCard>
  </CenterLayout>
</template>

<script>
import { branding } from '@/components/mixins'
import DefaultCard from '@/components/DefaultCard'
import CenterLayout from '@/layouts/center'
import api from '@/api'

export default {
  components: { DefaultCard, CenterLayout },
  mixins: [branding],
  data() {
    return {
      payload: {
        email: '',
      },
      errors: {},
      loading: false,
    }
  },
  methods: {
    async resetPassword() {
      this.loading = true
      try {
        await api.post('/api/registration/reset-password', this.payload)
        this.notifySuccess(this.$t('notifyPasswordResetMail'))
        this.$router.replace({ name: 'home' })
      } catch ({ response: { data: errors } }) {
        this.errors = errors
      }
      this.loading = false
    },
  },
}
</script>
