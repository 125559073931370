<template>
  <DefaultLayout :info="info">
    <v-row class="shrink">
      <v-col v-for="app in info.apps" :key="app.id" cols="12" :md="hasSingleApp ? 12 : 6">
        <v-card :href="app.url" class="app-card" :style="appBorderColor(app)" height="100%" hover>
          <v-card-title class="d-flex justify-space-between align-center">
            <h2 class="text-subtitle-1">{{ app.title }}</h2>
            <div v-if="app.icon" v-dompurify-html="app.icon" class="icon-container"></div>
          </v-card-title>
          <v-card-text>
            {{ app.subtitle }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </DefaultLayout>
</template>

<script>
import { loginRequired } from '@/components/mixins'
import DefaultLayout from '@/layouts/default'

import get from 'lodash/get'
import size from 'lodash/size'

export default {
  components: { DefaultLayout },
  mixins: [loginRequired],
  computed: {
    hasSingleApp() {
      return size(this.info.apps) === 1
    },
  },
  methods: {
    appBorderColor(app) {
      const color = get(app, 'color', '#eaeaea')
      return {
        'border-left-color': color,
      }
    },
  },
}
</script>
<style>
.icon-container > svg {
  width: 30px !important;
  height: 30px !important;
}
.app-card {
  border-left-style: solid;
  border-left-width: 5px;
}
</style>
