<template>
  <v-form ref="form">
    <v-card>
      <v-card-title>{{ $t('reset2FA') }}</v-card-title>
      <v-card-text>
        <i18n path="reset2FAConfirm" tag="p" class="body-2">
          <template v-slot:user>
            <b>{{ `${device.username} (${device.email})` }}</b>
          </template>
        </i18n>
        <p>{{ $t('reset2FAConfirmHelp') }}</p>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" depressed @click="close">{{ $t('cancel') }}</v-btn>
        <v-btn color="primary" depressed @click="deleteDevice">{{ $t('reset') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import api from '@/api'

export default {
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  methods: {
    async deleteDevice() {
      try {
        await api.delete(`/api/totp-devices/${this.device.id}`)
        this.$emit('close-dialog')
        this.$emit('reload-devices')
      } catch (error) {
        console.log(error.response)
      }
    },
    close() {
      this.$emit('close-dialog')
    },
  },
}
</script>
