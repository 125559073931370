<template>
  <v-form ref="form" v-model="valid" @submit.prevent="save">
    <v-card>
      <v-card-title>{{ $t('editUser') }}</v-card-title>
      <v-card-text>
        <v-container class="pa-0">
          <v-row>
            <v-col v-if="errorMessages.non_field_errors" cols="12">
              <span class="error--text body-1">{{ $t('anErrorOccurred') }} {{ errorMessages.non_field_errors }}</span>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="user.last_name"
                :label="$t('lastName')"
                :rules="[rules.required]"
                :loading="loading"
                :error-messages="errorMessages.last_name"
                dense
                autofocus
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="user.first_name"
                :rules="[rules.required]"
                :label="$t('firstName')"
                :loading="loading"
                :error-messages="errorMessages.first_name"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="user.email"
                :label="$t('email')"
                :rules="[rules.required, rules.email]"
                :loading="loading"
                :error-messages="errorMessages.email"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="user.username" :label="$t('username')" dense readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="user.phone_number"
                :label="$t('phoneNumber')"
                :loading="loading"
                :error-messages="errorMessages.phone_number"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="user.mobile_number"
                :label="$t('mobileNumber')"
                :loading="loading"
                :error-messages="errorMessages.mobile_number"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="user.title"
                :label="$t('title')"
                :loading="loading"
                :error-messages="errorMessages.title"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="user.organization"
                :label="$t('organization')"
                :loading="loading"
                :error-messages="errorMessages.organization"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="user.street"
                :label="$t('street')"
                :loading="loading"
                :error-messages="errorMessages.street"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="user.zip_code"
                :label="$t('zipCode')"
                :loading="loading"
                :error-messages="errorMessages.zip_code"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="9">
              <v-text-field
                v-model="user.location"
                :label="$t('location')"
                :loading="loading"
                :error-messages="errorMessages.location"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" depressed @click="close">{{ $t('cancel') }}</v-btn>
        <v-btn :disabled="!valid" type="submit" color="primary" depressed>{{ $t('save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import api from '@/api'

export default {
  props: {
    uuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      valid: true,
      user: {},
      errorMessages: {},
      rules: {
        required: (value) => !!value || this.$t('inputRequired'),
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || this.$t('invalidEmail')
        },
      },
    }
  },
  watch: {
    uuid: {
      handler() {
        this.fetchUser()
      },
      immediate: true,
    },
  },
  methods: {
    async fetchUser() {
      this.loading = true
      const resp = await api.get(`/api/users/${this.uuid}`)
      this.user = resp.data
      this.loading = false
    },
    close() {
      this.errorMessages = {}
      this.$refs.form.reset()
      this.$emit('close-dialog')
    },
    async save() {
      const data = {
        last_name: this.user.last_name,
        first_name: this.user.first_name,
        email: this.user.email,
        phone_number: this.user.phone_number || null,
        mobile_number: this.user.mobile_number || null,
        title: this.user.title || null,
        organization: this.user.organization || null,
        street: this.user.street || null,
        zip_code: this.user.zip_code || null,
        location: this.user.location || null,
      }
      this.loading = true
      try {
        const resp = await api.patch(`/api/users/${this.uuid}`, data)
        this.user = resp.data
        this.$emit('close-dialog')
        this.$emit('reload-users')
      } catch (error) {
        if (error.response && error.response.status === 400) {
          this.errorMessages = error.response.data
        }
        console.log(error)
      }
      this.loading = false
    },
  },
}
</script>
