<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
    <Notification />
    <v-component :is="footer" v-if="componentExists(footer)" />
  </v-app>
</template>

<script>
import Notification from '@/components/Notification'
import { branding } from '@/components/mixins'

export default {
  name: 'App',
  components: { Notification },
  mixins: [branding],
}
</script>
