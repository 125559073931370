import Vue from 'vue'
import bootstrap from '@/bootstrap'
import { registerComponents, getAssetURL } from '@/theme'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import { mapState } from 'vuex'
import App from './App.vue'
import createRouter from './router'
import store from './store'
import createVuetify from './plugins/vuetify'
import { i18n } from './plugins/i18n'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@/mixins'

Vue.use(VueDOMPurifyHTML)

Vue.config.productionTip = false
;(async () => {
  /**
   * The URL of the first request to the config endpoint is the current location
   * of the document. The response of the config endpoint includes the PATH_PREFIX
   * setting of django.
   * Example Assuming the PATH_PREFIX is `/portal`:
   * Visit http://localhost:8081/portal/foo/bar -> XHR request to http://localhost:8081/portal/foo/bar/api/config
   * Response gives back the rootURL: "/portal".
   * Set the baseURL of the api to this rootURL so further requests
   * go to http://localhost:8081/portal/api/* with the rootURL as the baseURL.
   */
  const config = await bootstrap()
  const router = createRouter(config)

  const configMixin = Vue.mixin({
    computed: {
      ...mapState('config', ['config']),
    },
  })

  new Vue({
    mixins: [configMixin],
    router,
    store,
    i18n,
    vuetify: createVuetify(config),
    beforeCreate() {
      this.$store.commit('config/setConfig', { config, router })
      this.$store.commit('config/setComponents', registerComponents())
    },
    mounted() {
      document.head.querySelector('#favicon').setAttribute(
        'href',
        getAssetURL({
          theme: config.theme,
          asset: 'favicon.ico',
        }),
      )
    },
    render: (h) => h(App),
  }).$mount('#app')
})()
