<template>
  <DefaultLayout :info="info">
    <v-data-table
      v-model="selected"
      :headers="tableHeaders"
      :items="groups"
      :search="search"
      :must-sort="true"
      sort-by="cn"
      :server-items-length="totalGroups"
      :loading="loading"
      :options.sync="options"
      :footer-props="{ itemsPerPageOptions: [10, 25, 100, 1000] }"
      item-key="uuid"
    >
      <template v-slot:top>
        <div class="d-flex align-center mb-4">
          <v-text-field v-model="search" single-line :label="$t('search')" clearable hide-details>
            <template #append>
              <v-icon small>fa-search</v-icon>
            </template>
          </v-text-field>
          <v-spacer></v-spacer>
          <v-tooltip top :open-delay="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn small color="green" dark depressed v-bind="attrs" v-on="on" @click="showAddDialog()"
                ><v-icon small>fa-plus</v-icon></v-btn
              >
            </template>
            <span>{{ $t('addGroup') }}</span>
          </v-tooltip>
          <v-dialog v-model="addGroupDialog" max-width="700px"
            ><AddGroupForm @close-dialog="closeAddDialog" @reload-groups="fetchGroups"></AddGroupForm>
          </v-dialog>
          <v-dialog v-model="deleteGroupDialog" max-width="500px">
            <DeleteGroupForm
              :group="deletedGroup"
              @close-dialog="closeDeleteDialog"
              @reload-groups="fetchGroups"
            ></DeleteGroupForm>
          </v-dialog>
          <v-dialog v-model="editGroupDialog" max-width="700px"
            ><EditGroupForm
              v-if="editedGroup"
              :uuid="editedGroup"
              @close-dialog="closeEditDialog"
              @reload-groups="fetchGroups"
            ></EditGroupForm>
          </v-dialog>
          <v-dialog v-model="assignUsersDialog" max-width="700px"
            ><AssignUsersForm
              v-if="editedGroup"
              :uuid="editedGroup"
              @close-dialog="closeAssignUsersDialog"
            ></AssignUsersForm>
          </v-dialog>
          <v-dialog v-model="setPasswordDialog" max-width="500px"
            ><SetPasswordForm
              v-if="editedGroup"
              :uuid="editedGroup"
              @close-dialog="closeSetPasswordDialog"
            ></SetPasswordForm>
          </v-dialog>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top :open-delay="500">
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2" v-bind="attrs" v-on="on" @click="showEditDialog(item)"> fa-edit </v-icon>
          </template>
          <span>{{ $t('editGroup') }}</span>
        </v-tooltip>
        <v-tooltip top :open-delay="500">
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2" v-bind="attrs" v-on="on" @click="showDeleteGroupDialog(item)"> fa-trash </v-icon>
          </template>
          <span>{{ $t('deleteGroup') }}</span>
        </v-tooltip>
        <v-tooltip top :open-delay="500">
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2" v-bind="attrs" v-on="on" @click="showAssignUsersDialog(item)"> fa-users </v-icon>
          </template>
          <span>{{ $t('assignUsers') }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </DefaultLayout>
</template>

<script>
import { loginRequired } from '@/components/mixins'
import EditGroupForm from '@/components/EditGroupForm'
import DeleteGroupForm from '@/components/DeleteGroupForm'
import AddGroupForm from '@/components/AddGroupForm'
import AssignUsersForm from '@/components/AssignUsersForm'
import DefaultLayout from '@/layouts/default'
import api from '@/api'
import debounce from 'lodash/debounce'

export default {
  components: { AddGroupForm, EditGroupForm, DeleteGroupForm, AssignUsersForm, DefaultLayout },
  mixins: [loginRequired],
  data() {
    return {
      addGroupDialog: false,
      editGroupDialog: false,
      deleteGroupDialog: false,
      assignUsersDialog: false,
      setPasswordDialog: false,
      editedGroup: null,
      deletedGroup: null,
      search: '',
      loading: false,
      options: {},
      totalGroups: 0,
      groups: [],
      tableHeaders: [
        { text: this.$t('group'), value: 'cn' },
        { text: this.$t('actions'), value: 'actions', sortable: false, width: 110, align: 'right' },
      ],
      selected: [],
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchGroups()
      },
      deep: true,
      immediate: true,
    },
    search() {
      this.fetchGroups()
    },
  },
  methods: {
    fetchGroups: debounce(async function fetchGroups() {
      this.loading = true
      try {
        const resp = await api.get('/api/groups/', {
          params: {
            page: this.options.page,
            page_size: this.options.itemsPerPage,
            sort_by: this.options.sortBy,
            sort_desc: this.options.sortDesc,
            search: this.search,
          },
        })
        this.groups = resp.data.results
        this.totalGroups = resp.data.count
      } catch (error) {
        this.groups = []
        this.totalGroups = 0
      }

      this.loading = false
    }, 300),
    showAddDialog() {
      this.addGroupDialog = true
    },
    closeAddDialog() {
      this.addGroupDialog = false
    },
    showEditDialog(group) {
      this.editedGroup = group.uuid
      this.editGroupDialog = true
    },
    closeEditDialog() {
      this.editGroupDialog = false
      this.editedGroup = null
    },
    showDeleteGroupDialog(group) {
      this.deletedGroup = group
      this.deleteGroupDialog = true
    },
    closeDeleteDialog() {
      this.deleteGroupDialog = false
      this.deletedGroup = {}
    },
    showAssignUsersDialog(group) {
      this.editedGroup = group.uuid
      this.assignUsersDialog = true
    },
    closeAssignUsersDialog() {
      this.assignUsersDialog = false
      this.editedGroup = null
    },
    showSetPasswordDialog(group) {
      this.editedGroup = group.uuid
      this.setPasswordDialog = true
    },
    closeSetPasswordDialog() {
      this.setPasswordDialog = false
      this.editedGroup = null
    },
  },
}
</script>
