import api, { setBaseURL } from '@/api'
import { loadLanguageAsync } from '@/plugins/i18n'

export default async function bootstrap() {
  const lang = navigator.language.toLocaleLowerCase().substring(0, 2)
  await loadLanguageAsync(lang)
  const { data } = await api.get('/api/config')
  setBaseURL(data.rootURL)
  return data
}
