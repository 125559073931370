<template>
  <CenterLayout>
    <DefaultCard>
      <template #title>
        {{ title }}
      </template>
      <v-form v-if="!enabled && otpauthUrl" @submit.prevent="submit">
        <v-card-text>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <p v-html="$t('tfaSetup.pleaseScanBarcode')"></p>
          <p>
            <tt data-testid="totp-key">{{ key }}</tt>
          </p>

          <p>
            {{ $t('recommendAuthenticatorApp') }}
            <v-tooltip top :open-delay="500">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  data-testid="show-help-dialog"
                  small
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="showHelpDialog()"
                >
                  fa-info-circle
                </v-icon>
              </template>
              <span>{{ $t('help') }}</span>
            </v-tooltip>
          </p>

          <div class="d-flex justify-center mb-4">
            <QRCode v-if="otpauthUrl" :value="otpauthUrl" :options="{ width: 200 }"></QRCode>
          </div>
          <p>{{ $t('enterCode') }}</p>
          <v-text-field
            v-model="token"
            :error-messages="errorMessages"
            prepend-icon="fa-key"
            name="token"
            label="Code"
            type="text"
            autofocus
            autocomplete="off"
          ></v-text-field>
        </v-card-text>

        <v-dialog v-model="helpDialogOpen" max-width="900px">
          <HelpDialog :title="title" @close-dialog="closeHelpDialog">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p v-html="$t('tfaSetup.installTOTPApp')"></p>
            <TOTPAppList />
            <br />
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p v-html="$t('tfaSetup.noPaidTOTPApp')"></p>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p v-html="$t('tfaSetup.setupTOTPApp')"></p>
          </HelpDialog>
        </v-dialog>

        <v-card-actions class="justify-end">
          <v-btn depressed :to="{ name: 'account' }" color="accent" min-width="100">{{ $t('cancel') }}</v-btn>
          <v-btn depressed type="submit" color="primary" min-width="100">{{ $t('activate') }}</v-btn>
        </v-card-actions>
      </v-form>
      <template v-else-if="enabled">
        <v-card-text>
          <p>{{ $t('tfaActivated.twoFactorAuthenticationActivated') }}</p>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <p v-html="$t('tfaActivated.twoFactorAuthenticationActivatedHelp')"></p>
        </v-card-text>
        <v-card-actions>
          <v-btn depressed :to="{ name: 'account' }" color="primary">{{ $t('continue') }}</v-btn>
        </v-card-actions>
      </template>
    </DefaultCard>
  </CenterLayout>
</template>

<script>
import api from '@/api'
import DefaultCard from '@/components/DefaultCard'
import TOTPAppList from '@/components/TOTPAppList'
import HelpDialog from '@/components/HelpDialog'
import CenterLayout from '@/layouts/center'
import QRCode from '../components/QRCode.vue'

export default {
  name: 'TOTPSetup',
  components: {
    QRCode,
    DefaultCard,
    HelpDialog,
    TOTPAppList,
    CenterLayout,
  },
  data: () => ({
    helpDialogOpen: false,
    key: '',
    token: '',
    otpauthUrl: '',
    enabled: false,
    errorMessages: [],
  }),
  computed: {
    title() {
      return this.enabled ? this.$t('twoFactorAuthentication') : this.$t('setupTwoFactorAuthentication')
    },
  },
  mounted() {
    this.renewSecret()
  },
  methods: {
    submit() {
      api
        .post('/api/totp', { key: this.key, token: parseInt(this.token, 10) })
        .then(() => {
          this.enabled = true
        })
        .catch((error) => {
          if (error.response.data.detail === 'Invalid token') {
            this.errorMessages = [this.$t('invalidCode')]
          } else if (error.response.status === 403) {
            this.errorMessages = ['Forbidden. Two-Step verification setup is disabled.']
          }
        })
    },
    renewSecret() {
      api
        .get('/api/totp')
        .then((res) => {
          this.key = res.data.key
          this.otpauthUrl = res.data.url
        })
        .catch((error) => {
          if (error.response.status === 401) this.$router.push({ name: 'login' })
          else if (error.response.status === 400 && error.response.data.detail === 'Already configured') {
            this.enabled = true
          }
        })
    },
    showHelpDialog() {
      this.helpDialogOpen = true
    },
    closeHelpDialog() {
      this.helpDialogOpen = false
    },
  },
}
</script>
