<script>
import QRCode from 'qrcode'

export default {
  name: 'Qrcode',

  props: {
    /**
     * The value of the QR code.
     */
    value: {
      type: String,
      default: () => '',
    },

    /**
     * The options for the QR code generator.
     * {@link https://github.com/soldair/node-qrcode#qr-code-options}
     */
    options: {
      type: Object,
      default: () => ({}),
    },
  },

  watch: {
    $props: {
      deep: true,
      immediate: true,

      /**
       * Update the QR code when props changed.
       */
      handler() {
        if (this.$el) {
          this.generate()
        }
      },
    },
  },

  mounted() {
    this.generate()
  },

  methods: {
    /**
     * Generate QR code.
     */
    generate() {
      const { options } = this
      const value = String(this.value)

      QRCode.toCanvas(this.$el, value, options, (error) => {
        if (error) {
          throw error
        }
      })
    },
  },

  render(createElement) {
    return createElement('canvas', this.$slots.default)
  },
}
</script>
