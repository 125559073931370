<template>
  <v-form ref="form" @submit.prevent="save">
    <v-card>
      <v-card-title>{{ $t('setPassword') }}</v-card-title>
      <v-card-text>
        <v-container class="pa-0">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="password"
                :label="$t('password')"
                :error-messages="passwordError"
                :append-icon="show ? 'fa-eye' : 'fa-eye-slash'"
                :type="show ? 'text' : 'password'"
                :loading="loading"
                dense
                autofocus
                @click:append="show = !show"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" depressed @click="close">{{ $t('cancel') }}</v-btn>
        <v-btn type="submit" color="primary" depressed>{{ $t('save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import api from '@/api'

export default {
  props: {
    uuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      password: null,
      passwordError: null,
      show: false,
    }
  },
  methods: {
    close() {
      this.passwordError = null
      this.$refs.form.reset()
      this.$emit('close-dialog')
    },
    async save() {
      const data = {
        password: this.password,
      }
      this.loading = true
      try {
        const resp = await api.patch(`/api/users/${this.uuid}`, data)
        this.user = resp.data
        this.$emit('close-dialog')
        this.$emit('reload-users')
      } catch (error) {
        if (error.response && error.response.status === 400) {
          this.passwordError = error.response.data.password
        }
        console.log(error)
      }
      this.loading = false
    },
  },
}
</script>
