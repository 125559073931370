<template>
  <v-card :style="{ width }" :tile="$vuetify.breakpoint.xs" :flat="$vuetify.breakpoint.xs">
    <v-toolbar v-if="!noTitle" dark color="primary" flat>
      <v-spacer />
      <v-toolbar-title>
        <slot name="title" />
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <slot />
  </v-card>
</template>
<script>
export default {
  name: 'DefaultCard',
  props: {
    wide: {
      type: Boolean,
      default: false,
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    width() {
      return this.wide ? '800px' : '400px'
    },
  },
}
</script>
