<template>
  <v-form ref="form" @submit.prevent="save">
    <v-card>
      <v-card-title>{{ $t('assignUsers') }}</v-card-title>
      <v-card-text>
        <v-container class="pa-0">
          <v-autocomplete
            v-model="assignedUsers"
            :items="users"
            :loading="loading"
            item-text="cn"
            item-value="dn"
            chips
            deletable-chips
            multiple
            autofocus
          ></v-autocomplete>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" depressed @click="close">{{ $t('cancel') }}</v-btn>
        <v-btn type="submit" color="primary" depressed>{{ $t('save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import api from '@/api'

export default {
  props: {
    uuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      assignedUsers: [],
      users: [],
      errorMessages: {},
    }
  },
  watch: {
    uuid: {
      handler() {
        this.fetchGroup()
        this.fetchUsers()
      },
      immediate: true,
    },
  },
  methods: {
    async fetchGroup() {
      this.loading = true
      const resp = await api.get(`/api/groups/${this.uuid}`)
      this.assignedUsers = resp.data.members.sort()
      this.loading = false
    },
    async fetchUsers() {
      this.loading = true
      const resp = await api.get('/api/users/?page_size=10000')
      this.users = resp.data.results
      this.loading = false
    },
    close() {
      this.errorMessages = {}
      this.$refs.form.reset()
      this.$emit('close-dialog')
    },
    async save() {
      const data = {
        members: this.assignedUsers,
      }
      this.loading = true
      try {
        await api.patch(`/api/groups/${this.uuid}`, data)
        this.$emit('close-dialog')
        this.$emit('reload-groups')
      } catch (error) {
        if (error.response && error.response.status === 400) {
          this.errorMessages = error.response.data
        }
      }
      this.loading = false
    },
  },
}
</script>
