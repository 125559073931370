<template>
  <v-snackbar
    :value="show"
    :color="severityColor"
    :data-severity="severity"
    data-testid="snackbar-container"
    top
    @input="close"
  >
    <span data-testid="snackbar-message">{{ message }}</span>
    <v-btn icon @click.native="close()">
      <v-icon>fa-times</v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { SEVERITY_COLOR_MAPPING } from '@/store/modules/notification'

export default {
  name: 'Notification',
  computed: {
    ...mapState('notification', ['show', 'message', 'severity']),
    severityColor() {
      return this.$vuetify.theme.themes.light[SEVERITY_COLOR_MAPPING[this.severity]]
    },
  },
  methods: {
    ...mapMutations({
      close: 'notification/close',
    }),
  },
}
</script>
