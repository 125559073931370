<template>
  <v-form ref="form">
    <v-card>
      <v-card-title>{{ $t('deleteGroup') }}</v-card-title>
      <v-card-text>
        <i18n path="deleteGroupConfirm" tag="p" class="body-2">
          <template v-slot:group>
            <b>{{ group.cn }}</b>
          </template>
        </i18n>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" depressed @click="close">{{ $t('cancel') }}</v-btn>
        <v-btn color="primary" depressed @click="deleteGroup">{{ $t('delete') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import api from '@/api'

export default {
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  methods: {
    async deleteGroup() {
      try {
        await api.delete(`/api/groups/${this.group.uuid}`)
        this.$emit('close-dialog')
        this.$emit('reload-groups')
      } catch (error) {}
    },
    close() {
      this.$emit('close-dialog')
    },
  },
}
</script>
