<template>
  <v-text-field
    v-bind="$attrs"
    prepend-icon="fa-key"
    :label="label"
    :type="showPlainText ? 'text' : 'password'"
    :append-icon="showPlainText ? 'fa-eye' : 'fa-eye-slash'"
    @click:append="showPlainText = !showPlainText"
    v-on="$listeners"
  >
    <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
    <template v-for="(index, name) in $scopedSlots" #[name]="data">
      <slot :name="name" v-bind="data"></slot>
    </template>
  </v-text-field>
</template>
<script>
export default {
  name: 'BasePasswordField',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default() {
        return this.$t('password')
      },
    },
  },
  data() {
    return {
      showPlainText: false,
    }
  },
}
</script>
