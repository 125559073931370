export default {
  state: {
    next: null,
  },
  mutations: {
    SET_NEXT(state, next) {
      state.next = next
    },
  },
  namespaced: true,
}
