<template>
  <CenterLayout v-if="info.error">
    <v-icon x-large color="error">fa-exclamation-circle</v-icon>
  </CenterLayout>
  <CenterLayout v-else-if="!info.is_authenticated">
    <v-progress-linear indeterminate color="primary"></v-progress-linear>
  </CenterLayout>
  <v-container v-else class="d-flex flex-column pt-4 pb-8" style="height: 100%; max-width: 900px">
    <div class="d-flex justify-space-between align-center mb-4">
      <router-link
        :to="{ name: 'home' }"
        class="d-flex align-center justify-space-between text-decoration-none grow mr-4"
      >
        <img :src="logoURL" style="max-height: 50px; max-width: 400px" />
        <h1 class="subtitle-2 d-none d-sm-block black--text">{{ title }}</h1>
      </router-link>
      <v-menu offset-y :nudge-bottom="5" tile transition="scroll-y-transition">
        <template #activator="{ on, attrs }">
          <v-btn fab depressed v-bind="attrs" small color="primary" data-testid="account-menu" v-on="on">
            <v-icon color="white" small>fa-user</v-icon>
          </v-btn>
        </template>
        <v-list dense class="py-0">
          <v-list-item class="py-2">
            <v-list-item-avatar color="primary" class="white--text">{{ info.user_abbreviation }}</v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ info.fullname }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ info.username }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item :to="{ name: 'home' }" exact>
            <v-list-item-icon class="mr-4 d-flex justify-center" style="min-width: 40px">
              <v-icon small>fa-th</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('applications') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'account' }" exact>
            <v-list-item-icon class="mr-4 d-flex justify-center" style="min-width: 40px">
              <v-icon small>fa-user</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('account') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'sessions' }" exact>
            <v-list-item-icon class="mr-4 d-flex justify-center" style="min-width: 40px">
              <v-icon small>fa-desktop</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('sessions') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <template v-if="config.manage_users_enabled || config.manage_2fa_enabled">
            <v-divider />
            <v-subheader class="px-4">{{ $t('administration') }}</v-subheader>
            <template v-if="config.manage_users_enabled">
              <v-list-item :to="{ name: 'manage-users' }" exact>
                <v-list-item-icon class="mr-4 d-flex justify-center" style="min-width: 40px">
                  <v-icon small>fa-user-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('manageUsers') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ name: 'manage-groups' }" exact>
                <v-list-item-icon class="mr-4 d-flex justify-center" style="min-width: 40px">
                  <v-icon small>fa-users-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('manageGroups') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item v-if="config.manage_2fa_enabled" :to="{ name: 'manage-2fa' }" exact>
              <v-list-item-icon class="mr-4 d-flex justify-center" style="min-width: 40px">
                <v-icon small>fa-qrcode</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('tfaManagement') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-divider />
          <v-list-item :to="{ name: 'logout' }">
            <v-list-item-icon class="mr-4 d-flex justify-center" style="min-width: 40px">
              <v-icon small>fa-sign-out-alt</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('logout') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-divider class="mb-8" />
    <slot />
  </v-container>
</template>
<script>
import { branding } from '@/components/mixins'
import CenterLayout from '@/layouts/center'

export default {
  name: 'DefaultLayout',
  components: { CenterLayout },
  mixins: [branding],
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
}
</script>
<style>
.user-menu > .v-btn__content {
  justify-content: start;
  flex-shrink: 1;
  overflow: hidden;
}
</style>
