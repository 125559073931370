<template>
  <v-container class="d-flex align-center justify-center flex-column" style="height: 100%">
    <img :src="logoURL" style="max-height: 100px; max-width: 400px" class="mb-6 shrink px-4" />
    <slot />
  </v-container>
</template>
<script>
import { branding } from '@/components/mixins'

export default {
  name: 'CenterLayout',
  mixins: [branding],
}
</script>
