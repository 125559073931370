<template>
  <CenterLayout>
    <DefaultCard>
      <template #title>
        {{ $t('approveChangeEmail') }}
      </template>
      <v-card-text v-if="errors.nonfield">
        <span v-for="error in errors.nonfield" :key="error" class="error--text body-2" style="white-space: pre-line">
          {{ error }}
        </span>
      </v-card-text>
      <v-card-text v-if="success" class="d-flex flex-column align-center">
        <p>{{ $t('changeEmailApproved') }}</p>
        <v-btn :to="{ name: 'home' }" color="primary" depressed>{{ $t('changeEmailDone') }}</v-btn>
      </v-card-text>
    </DefaultCard>
  </CenterLayout>
</template>

<script>
import { branding } from '@/components/mixins'
import api from '@/api'
import DefaultCard from '@/components/DefaultCard'
import CenterLayout from '@/layouts/center'

export default {
  components: { DefaultCard, CenterLayout },
  mixins: [branding],
  data() {
    return {
      success: false,
      errors: {},
    }
  },
  async mounted() {
    try {
      await api.post('/api/approve-change-email', { payload: this.$route.query.payload })
      this.success = true
    } catch ({ response: { data: errors } }) {
      this.errors = errors
    }
  },
}
</script>
