import { mapState } from 'vuex'
import { getAssetURL } from '@/theme'
import redirect from '@/redirect'
import api from '@/api'

export const branding = {
  computed: {
    logoURL() {
      return getAssetURL({ theme: this.theme, asset: 'logo.svg' })
    },
    footer() {
      return `${this.theme}-Footer`
    },
    externalLoginText() {
      return `${this.theme}-ExternalLoginText`
    },
    ...mapState('config', {
      theme: (state) => state.config.theme,
      title: (state) => state.config.title,
      components: (state) => state.components,
    }),
  },
  methods: {
    componentExists(name) {
      return this.components.includes(name)
    },
  },
}

export const loginRequired = {
  data() {
    return {
      info: {
        is_authenticated: false,
        login_url: null,
        apps: [],
        user_abbreviation: '',
        sessions: [],
        error: false,
      },
    }
  },
  mounted() {
    // Redirect to next URL
    const next = this.$store.state.login.next
    if (next) {
      redirect(next).catch(() => {
        this.fetchInfo()
      })
      return
    }
    this.fetchInfo()
  },
  methods: {
    async fetchInfo() {
      try {
        const info = (await api.get('/api/info')).data
        if (this.$route.query.next && info.is_authenticated) {
          redirect(this.$route.query.next)
        } else {
          this.info = info
        }
      } catch (error) {
        this.info.error = true
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchInfo()
    next()
  },
  watch: {
    'info.login_url'(loginURL) {
      if (loginURL) {
        const relativeLoginURL = loginURL.replace(this.$router.options.base, '')
        if (this.$router.matcher.match(relativeLoginURL).name === 'login') {
          // When login points to the login comopnent in the frontend
          const query = this.$route.query.next ? { next: this.$route.query.next } : {}
          this.$router.push({ path: relativeLoginURL, query })
        } else {
          // When login points to the backend e.g. oidc (openidconnect)
          const url = new URL(loginURL, window.location.href)
          if (this.$route.query.next) url.searchParams.set('next', this.$route.query.next)
          redirect(url.href)
        }
      }
    },
  },
}
