<template>
  <v-dialog v-model="deleteUserDialog" max-width="600">
    <template #activator="props">
      <slot name="activator" v-bind="props" />
    </template>
    <v-card :loading="deleteAccountLoading">
      <v-card-title class="error white--text">
        {{ $t('delete_user') }}
      </v-card-title>
      <v-card-text class="py-4">
        <p v-if="deleteUserError" class="error--text">{{ deleteUserError }}</p>
        <p v-if="inquiryRejectReason" class="error--text">{{ inquiryRejectReason }}</p>
        <p v-else>{{ $t('confirm_delete_user_hint') }}</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="accent" depressed @click="deleteUserDialog = false">{{ $t('cancel') }}</v-btn>
        <v-btn
          color="error"
          :disabled="!!inquiryRejectReason || deleteAccountLoading"
          depressed
          @click="deleteAccount"
          >{{ $t('confirm_delete_user') }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import get from 'lodash/get'
import api from '@/api'

export default {
  data() {
    return {
      deleteUserDialog: false,
      deleteUserError: '',
      deleteAccountLoading: false,
      inquiryRejectReason: '',
      inquiryLoading: false,
    }
  },
  mounted() {
    if (this.config.has_web_hooks) {
      this.inquireDeletion()
    }
  },
  methods: {
    async deleteAccount() {
      this.deleteAccountLoading = true
      this.deleteUserDialog = true
      try {
        await api.delete('/api/delete-me')
        this.$router.push({ name: 'login' })
      } catch (error) {
        this.deleteUserError = get(error, 'response.data.non_field_errors')
      } finally {
        this.deleteAccountLoading = false
      }
    },
    async inquireDeletion() {
      this.inquiryLoading = true
      this.inquiryRejectReason = ''
      try {
        await api.post('/api/inquire-me')
      } catch (error) {
        if (error.response.status === 412) {
          this.inquiryRejectReason = error.response.data.reason
        } else {
          this.inquiryRejectReason = this.$t('inquiryNotPossible')
          throw error
        }
      } finally {
        this.inquiryLoading = false
      }
    },
  },
}
</script>
