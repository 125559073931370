<template>
  <v-container>
    <v-row>
      <v-col v-for="app in apps" :key="app.name">
        <div class="authenticator-app">
          <img class="authenticator-app-logo" :src="app.logoURL" />
          <div v-if="!iconsOnly" class="authenticator-app-text">
            <strong>{{ app.name }} </strong><br />
            (<a :href="app.urls.ios">iOS</a> | <a :href="app.urls.android">Android</a>)
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getAssetURL } from '@/theme'

export default {
  name: 'TOTPAppList',
  props: {
    iconsOnly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    apps: [
      {
        name: 'Authy',
        logoURL: getAssetURL({ asset: 'AppAuthy.png' }),
        urls: {
          ios: 'https://apps.apple.com/ch/app/twilio-authy/id494168017',
          android: 'https://play.google.com/store/apps/details?id=com.authy.authy&hl=de_CH',
        },
      },
      {
        name: 'Google Authenticator',
        logoURL: getAssetURL({ asset: 'AppGoogleAuthenticator.png' }),
        urls: {
          ios: 'https://apps.apple.com/ch/app/google-authenticator/id388497605',
          android: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=de_CH',
        },
      },
      {
        name: 'Microsoft Authenticator',
        logoURL: getAssetURL({ asset: 'AppMicrosoftAuthenticator.png' }),
        urls: {
          ios: 'https://apps.apple.com/ch/app/microsoft-authenticator/id983156458',
          android: 'https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=de_CH',
        },
      },
    ],
  }),
}
</script>
<style lang="scss" scoped>
.authenticator-app {
  display: flex;
  justify-content: center;
}

.authenticator-app-logo {
  border-radius: 12px;
  border-width: 0;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
  height: 48px;
  width: 48px;
}

.authenticator-app-text {
  margin-left: 1.5em;
  width: 100%;
}
</style>
