<template>
  <DefaultLayout :info="info">
    <div class="d-flex justify-space-between">
      <div>
        <strong class="d-block">{{ $t('name') }}:</strong>
        <span class="d-block" style="max-width: 400px">{{ info.fullname }}</span>
      </div>
    </div>
    <v-divider class="my-8" />
    <div class="d-flex justify-space-between">
      <div>
        <strong class="d-block">{{ $t('username') }}:</strong>
        <span class="d-block" style="max-width: 400px">{{ info.username }}</span>
      </div>
    </div>
    <v-divider class="my-8" />
    <div class="d-flex justify-space-between flex-column flex-sm-row">
      <div class="mb-4 mb-sm-0">
        <strong class="d-block">{{ $t('emailAddress') }}:</strong>
        <span class="d-block" style="max-width: 400px">{{ info.email }}</span>
      </div>
      <v-btn
        small
        color="primary"
        :disabled="!config.change_email_enabled"
        depressed
        :to="config.change_email_enabled ? { name: 'change-email' } : {}"
        >{{ $t('changeEmail') }}</v-btn
      >
    </div>
    <v-divider class="my-8" />
    <div class="d-flex justify-space-between flex-column flex-sm-row">
      <div class="mb-4 mb-sm-0">
        <strong class="d-block">{{ $t('password') }}:</strong>
        <span class="d-block" style="max-width: 400px">*******</span>
      </div>
      <v-btn
        color="primary"
        small
        :disabled="!config.change_password_enabled"
        :to="config.change_password_enabled ? { name: 'change-password' } : {}"
        depressed
        >{{ $t('changePassword') }}</v-btn
      >
    </div>
    <template v-if="info.tfa_setup_allowed">
      <v-divider class="my-8" />
      <div class="d-flex justify-space-between flex-column flex-sm-row">
        <div class="mb-4 mb-sm-0">
          <strong class="d-block">{{ $t('twoFactorAuthentication') }}:</strong>
          <span class="d-block" style="max-width: 400px">{{ $t('twoFactorAuthenticationHind') }}</span>
        </div>
        <v-btn color="primary" small depressed @click="toggleTOTP">
          {{ tfaLabel }}
        </v-btn>
      </div>
    </template>
    <v-divider class="my-8" />
    <div class="d-flex justify-space-between flex-column flex-sm-row">
      <div class="mb-4 mb-sm-0">
        <strong class="red--text d-block">{{ $t('delete_user') }}:</strong>
        <span class="d-block" style="max-width: 400px">{{ $t('delete_user_hint') }}</span>
      </div>
      <DeleteMyAccountDialog>
        <template #activator="{ on, attrs }">
          <v-btn small v-bind="attrs" :disabled="!config.delete_user_is_enabled" color="error" depressed v-on="on">{{
            $t('delete_user')
          }}</v-btn>
        </template>
      </DeleteMyAccountDialog>
    </div>
  </DefaultLayout>
</template>
<script>
import DefaultLayout from '@/layouts/default'
import DeleteMyAccountDialog from '@/components/DeleteMyAccountDialog'
import { loginRequired } from '@/components/mixins'
export default {
  components: { DefaultLayout, DeleteMyAccountDialog },
  mixins: [loginRequired],
  computed: {
    tfaLabel() {
      return this.info.tfa_enabled ? this.$t('deactivate') : this.$t('activate')
    },
  },
  methods: {
    toggleTOTP() {
      if (this.info.tfa_enabled) {
        this.$router.push({ name: 'disable-totp' })
      } else {
        this.$router.push({ name: 'setup-totp' })
      }
    },
  },
}
</script>
