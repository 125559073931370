<template>
  <v-card>
    <v-card-title>{{ title }}</v-card-title>
    <v-card-text>
      <slot></slot>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="accent" depressed @click="close">{{ $t('close') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    close() {
      this.$emit('close-dialog')
    },
  },
}
</script>
