import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '@/locales/en'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: { en: messages },
})

const loadedLanguages = ['en'] // our default language that is preloaded

function setI18nLanguage(lang) {
  i18n.locale = lang
  return lang
}

export function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  let messages

  try {
    messages = require(/* webpackChunkName: "i18n-[request]" */ `@/locales/${lang}`)
  } catch (error) {
    return loadLanguageAsync(i18n.fallbackLocale)
  }

  i18n.setLocaleMessage(lang, messages.default)
  loadedLanguages.push(lang)
  return setI18nLanguage(lang)
}
