import axios from 'axios'

const api = axios.create({
  baseURL: window.location.pathname,
  headers: {
    Accept: 'application/json',
  },
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
})

export function setBaseURL(baseURL) {
  api.defaults.baseURL = baseURL
}

export default api
