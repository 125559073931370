import api from '@/api'

export default {
  state: {
    components: [],
    config: {},
  },
  mutations: {
    setConfig(state, { config, router }) {
      state.config = config
    },
    setComponents(state, components) {
      state.components = components
    },
  },
  actions: {
    async fetchConfig({ commit }, router) {
      const config = await api.get('/api/config')
      commit('setConfig', { config: config.data, router })
      return config
    },
  },
  namespaced: true,
}
