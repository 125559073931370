import api from '@/api'

function isURL(url) {
  try {
    // eslint-disable-next-line no-new
    new URL(url)
    return true
  } catch {
    return false
  }
}

export default async function redirect(url) {
  if (!isURL(url)) {
    window.location.href = url
  } else {
    await api.get('/api/validate_redirect_url', { params: { url } })
    window.location.href = url
  }
}
