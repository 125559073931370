import Vue from 'vue'
import Router from 'vue-router'
import { requireLogin } from '@/auth'
import SelfRegistration from '@/views/SelfRegistration.vue'
import ChangePassword from '@/views/ChangePassword.vue'
import ResetPassword from '@/views/ResetPassword.vue'
import ResetPasswordConfirm from '@/views/ResetPasswordConfirm.vue'
import Onboard from '@/views/Onboard.vue'
import ManageUsers from '@/views/ManageUsers.vue'
import ManageGroups from '@/views/ManageGroups.vue'
import Manage2FA from '@/views/Manage2FA.vue'
import ChangeEmail from '@/views/ChangeEmail.vue'
import ApproveChangeEmail from '@/views/ApproveChangeEmail.vue'
import Registration from '@/views/Registration.vue'
import DisableTOTP from '@/views/DisableTOTP.vue'
import SetupTOTP from '@/views/SetupTOTP.vue'
import Logout from '@/views/Logout.vue'
import Login from '@/views/Login.vue'
import Home from '@/views/Home.vue'
import Account from '@/views/Account.vue'
import Sessions from '@/views/Sessions.vue'

const conditionalRoutes = {
  change_email_enabled: [
    {
      path: '/change-email',
      name: 'change-email',
      component: ChangeEmail,
    },
    {
      path: '/approve-change-email',
      name: 'approve-change-email',
      component: ApproveChangeEmail,
    },
  ],
  self_registration_enabled: [
    {
      path: '/self-registration',
      name: 'self-registration',
      component: SelfRegistration,
    },
  ],
  change_password_enabled: [
    {
      path: '/change-password',
      name: 'change-password',
      component: ChangePassword,
      beforeEnter: requireLogin,
    },
  ],
  reset_password_enabled: [
    {
      path: '/reset-password',
      name: 'reset-password',
      component: ResetPassword,
    },
    {
      // This path is also used in ianus/registration/templates/registration_mails/password_reset_email.html
      path: '/reset-password-confirm/:user_id/:token',
      name: 'reset-password-confirm',
      component: ResetPasswordConfirm,
    },
  ],
  onboarding_enabled: [
    {
      path: '/onboard',
      name: 'onboard',
      component: Onboard,
    },
  ],
  manage_users_enabled: [
    {
      path: '/manage-users',
      name: 'manage-users',
      component: ManageUsers,
    },
    {
      path: '/manage-groups',
      name: 'manage-groups',
      component: ManageGroups,
    },
  ],
  manage_2fa_enabled: [
    {
      path: '/manage-2fa',
      name: 'manage-2fa',
      component: Manage2FA,
    },
  ],
}

function routeExists(route, router) {
  return router
    .getRoutes()
    .map((r) => r.name)
    .includes(route.name)
}

export function configureRoutes(config, router) {
  Object.entries(conditionalRoutes).forEach(([configKey, routes]) => {
    const isEnabled = config[configKey]
    if (!isEnabled) {
      return
    }
    routes.forEach((route) => {
      if (!routeExists(route, router)) {
        router.addRoute(route)
      }
    })
  })
}

Vue.use(Router)

export default function createRouter(config = {}) {
  const router = new Router({
    mode: 'history',
    base: config.rootURL || process.env.BASE_URL,
    routes: [
      {
        path: '/',
        name: 'home',
        component: Home,
      },
      {
        path: '/login',
        name: 'login',
        component: Login,
      },
      {
        path: '/logout',
        name: 'logout',
        component: Logout,
      },
      {
        path: '/setup-totp',
        name: 'setup-totp',
        component: SetupTOTP,
      },
      {
        path: '/disable-totp',
        name: 'disable-totp',
        component: DisableTOTP,
      },
      {
        path: '/registration',
        name: 'registration',
        component: Registration,
      },
      {
        path: '/account',
        name: 'account',
        component: Account,
      },
      {
        path: '/sessions',
        name: 'sessions',
        component: Sessions,
      },
      {
        path: '*',
        redirect: { name: 'home' },
      },
    ],
  })
  configureRoutes(config, router)
  return router
}
