<template>
  <CenterLayout>
    <DefaultCard>
      <template #title>
        {{ $t('changePassword') }}
      </template>
      <v-form @submit.prevent="changePassword">
        <v-card-text v-if="errors.nonfield">
          <span v-for="error in errors.nonfield" :key="error" class="error--text body-2" style="white-space: pre-line">
            {{ error }}
          </span>
        </v-card-text>
        <v-card-text>
          <BasePasswordField
            v-model="payload.current_password"
            :label="$t('currentPassword')"
            class="mb-2"
            :error-messages="errors.current_password"
            autofocus
          />
          <PasswordField
            v-model="payload.new_password"
            :label="$t('newPassword')"
            :error-messages="errors.new_password"
          />
          <BasePasswordField
            v-model="payload.reentered_password"
            class="mt-6"
            :label="$t('reenterPassword')"
            :error-messages="errors.reentered_password"
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn depressed :to="{ name: 'account' }" color="accent" min-width="120">{{ $t('cancel') }}</v-btn>
          <v-btn depressed type="submit" color="primary" min-width="120">{{ $t('change') }}</v-btn>
        </v-card-actions>
      </v-form>
    </DefaultCard>
  </CenterLayout>
</template>

<script>
import { branding } from '@/components/mixins'
import api from '@/api'
import PasswordField from '@/components/PasswordField'
import DefaultCard from '@/components/DefaultCard'
import BasePasswordField from '@/components/BasePasswordField.vue'
import CenterLayout from '@/layouts/center'

export default {
  components: { PasswordField, BasePasswordField, DefaultCard, CenterLayout },
  mixins: [branding],
  data() {
    return {
      payload: {
        current_password: '',
        new_password: '',
        reentered_password: '',
      },
      errors: {},
    }
  },
  methods: {
    async changePassword() {
      try {
        await api.post('/api/registration/change-password', this.payload)
        this.notifySuccess(this.$t('notifyPasswordChanged'))
        this.$router.replace({ name: 'account' })
      } catch ({ response: { data: errors } }) {
        this.errors = errors
      }
    },
  },
}
</script>
