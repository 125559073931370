import api from '@/api'

export async function isAuthenticated() {
  try {
    const {
      data: { is_authenticated: isAuth },
    } = await api.get('/api/info')
    return isAuth
  } catch (error) {
    return false
  }
}

export async function requireLogin(to, from, next) {
  if (await isAuthenticated()) {
    next()
  } else {
    next({ name: 'login' })
  }
}
