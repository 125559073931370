<template>
  <CenterLayout>
    <DefaultCard>
      <template #title>
        {{ title }}
      </template>
      <template v-if="state === 'loggedOut' && !shouldRedirect">
        <v-card-text>
          {{ $t('loggedOutSuccessfully') }}
        </v-card-text>
        <v-card-actions class="flex-column">
          <v-btn depressed block color="primary" @click="login">{{ $t('login') }}</v-btn>
          <v-btn
            v-if="config.spnego"
            depressed
            block
            color="primary"
            :to="{ name: 'login', query: { sso: 'off' } }"
            class="mx-0 mt-4"
          >
            {{ $t('loginWithoutSSO') }}
          </v-btn>
        </v-card-actions>
      </template>
      <template v-else-if="state === 'logoutFailed'">
        <v-card-text>
          {{ $t('logoutNotSuccessful') }}
        </v-card-text>
        <v-card-actions>
          <v-btn depressed block color="primary" @click="logout">{{ $t('tryAgain') }}</v-btn>
        </v-card-actions>
      </template>
      <div v-else-if="state === 'loggedIn'" class="d-flex align-center justify-center my-8">
        <v-progress-circular :size="40" :width="1" color="primary" indeterminate />
      </div>
    </DefaultCard>
  </CenterLayout>
</template>

<script>
import api from '@/api'
import DefaultCard from '@/components/DefaultCard'
import CenterLayout from '@/layouts/center'

export default {
  components: {
    DefaultCard,
    CenterLayout,
  },
  props: {
    source: {
      type: String,
      default: () => '',
    },
  },
  data: () => ({
    state: 'loggedIn',
    loginURL: null,
    logoutRedirectURL: null,
  }),
  computed: {
    title() {
      switch (this.state) {
        case 'loggedOut':
          return this.$t('loggedOut')
        case 'logoutFailed':
          return this.$t('logoutFailed')
        case 'loggedIn':
          return this.$t('loggingOut')
        default:
          return ''
      }
    },
    shouldRedirect() {
      if (this.logoutRedirectURL.startsWith('http')) {
        return this.logoutRedirectURL !== window.location.href
      } else {
        return this.logoutRedirectURL !== window.location.pathname
      }
    },
  },
  mounted() {
    this.logout()
  },
  methods: {
    logout() {
      api
        .post('/api/logout')
        .then((res) => {
          this.state = 'loggedOut'
          this.loginURL = res.data.login_url
          this.logoutRedirectURL = res.data.logout_redirect_url
          if (this.shouldRedirect) window.location.href = this.logoutRedirectURL
        })
        .catch(() => {
          this.state = 'logoutFailed'
        })
    },
    login() {
      const route = this.$router.resolve(this.loginURL)
      if (route.resolved.fullPath === '/') {
        window.location = this.loginURL
      } else {
        this.$router.push(this.loginURL)
      }
    },
  },
}
</script>
