<template>
  <DefaultLayout :info="info">
    <v-data-table
      v-model="selected"
      :headers="tableHeaders"
      :items="users"
      :search="search"
      :must-sort="true"
      :server-items-length="totalUsers"
      :loading="loading"
      :options.sync="options"
      :footer-props="{ itemsPerPageOptions: [10, 25, 100, 1000] }"
      item-key="uuid"
    >
      <template v-slot:top>
        <div class="d-flex align-center mb-4">
          <v-text-field v-model="search" :label="$t('search')" single-line clearable hide-details>
            <template #append>
              <v-icon small>fa-search</v-icon>
            </template>
          </v-text-field>
          <v-spacer></v-spacer>
          <v-tooltip top :open-delay="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn small color="green" dark depressed v-bind="attrs" v-on="on" @click="showAddDialog()"
                ><v-icon small>fa-plus</v-icon></v-btn
              >
            </template>
            <span>{{ $t('addUser') }}</span>
          </v-tooltip>
          <v-dialog v-model="addUserDialog" max-width="700px"
            ><AddUserForm @close-dialog="closeAddDialog" @reload-users="fetchUsers"></AddUserForm>
          </v-dialog>
          <v-dialog v-model="deleteUserDialog" max-width="500px">
            <DeleteUserForm
              :user="deletedUser"
              @close-dialog="closeDeleteDialog"
              @reload-users="fetchUsers"
            ></DeleteUserForm>
          </v-dialog>
          <v-dialog v-model="editUserDialog" max-width="700px"
            ><EditUserForm
              v-if="editedUser"
              :uuid="editedUser"
              @close-dialog="closeEditDialog"
              @reload-users="fetchUsers"
            ></EditUserForm>
          </v-dialog>
          <v-dialog v-model="assignGroupsDialog" max-width="700px"
            ><AssignGroupsForm
              v-if="editedUser"
              :uuid="editedUser"
              @close-dialog="closeAssignGroupsDialog"
            ></AssignGroupsForm>
          </v-dialog>
          <v-dialog v-model="setPasswordDialog" max-width="500px"
            ><SetPasswordForm
              v-if="editedUser"
              :uuid="editedUser"
              @close-dialog="closeSetPasswordDialog"
            ></SetPasswordForm>
          </v-dialog>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top :open-delay="500">
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2" v-bind="attrs" v-on="on" @click="showEditDialog(item)"> fa-edit </v-icon>
          </template>
          <span>{{ $t('editUser') }}</span>
        </v-tooltip>
        <v-tooltip top :open-delay="500">
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2" v-bind="attrs" v-on="on" @click="showDeleteUserDialog(item)"> fa-trash </v-icon>
          </template>
          <span>{{ $t('deleteUser') }}</span>
        </v-tooltip>
        <v-tooltip top :open-delay="500">
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2" v-bind="attrs" v-on="on" @click="showAssignGroupsDialog(item)">
              fa-users
            </v-icon>
          </template>
          <span>{{ $t('assignGroups') }}</span>
        </v-tooltip>
        <v-tooltip top :open-delay="500">
          <template v-slot:activator="{ on, attrs }">
            <v-icon small v-bind="attrs" v-on="on" @click="showSetPasswordDialog(item)"> fa-key </v-icon>
          </template>
          <span>{{ $t('setPassword') }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </DefaultLayout>
</template>

<script>
import { loginRequired } from '@/components/mixins'
import EditUserForm from '@/components/EditUserForm'
import DeleteUserForm from '@/components/DeleteUserForm'
import AddUserForm from '@/components/AddUserForm'
import AssignGroupsForm from '@/components/AssignGroupsForm'
import SetPasswordForm from '@/components/SetPasswordForm'
import DefaultLayout from '@/layouts/default'
import api from '@/api'
import debounce from 'lodash/debounce'

export default {
  components: {
    AddUserForm,
    EditUserForm,
    DeleteUserForm,
    AssignGroupsForm,
    SetPasswordForm,
    DefaultLayout,
  },
  mixins: [loginRequired],
  data() {
    return {
      addUserDialog: false,
      editUserDialog: false,
      deleteUserDialog: false,
      assignGroupsDialog: false,
      setPasswordDialog: false,
      editedUser: null,
      deletedUser: null,
      search: '',
      loading: false,
      options: {},
      totalUsers: 0,
      users: [],
      tableHeaders: [
        { text: this.$t('lastName'), value: 'last_name' },
        { text: this.$t('firstName'), value: 'first_name' },
        { text: this.$t('email'), value: 'email' },
        { text: this.$t('actions'), value: 'actions', sortable: false, width: 130, align: 'right' },
      ],
      selected: [],
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchUsers()
      },
      deep: true,
      immediate: true,
    },
    search() {
      this.fetchUsers()
    },
  },
  methods: {
    fetchUsers: debounce(async function fetchUsers() {
      this.loading = true
      try {
        const resp = await api.get('/api/users/', {
          params: {
            page: this.options.page,
            page_size: this.options.itemsPerPage,
            sort_by: this.options.sortBy,
            sort_desc: this.options.sortDesc,
            search: this.search,
          },
        })
        this.users = resp.data.results
        this.totalUsers = resp.data.count
      } catch (error) {
        this.users = []
        this.totalUsers = 0
      }

      this.loading = false
    }, 300),
    showAddDialog() {
      this.addUserDialog = true
    },
    closeAddDialog() {
      this.addUserDialog = false
    },
    showEditDialog(user) {
      this.editedUser = user.uuid
      this.editUserDialog = true
    },
    closeEditDialog() {
      this.editUserDialog = false
      this.editedUser = null
    },
    showDeleteUserDialog(user) {
      this.deletedUser = user
      this.deleteUserDialog = true
    },
    closeDeleteDialog() {
      this.deleteUserDialog = false
      this.deletedUser = {}
    },
    showAssignGroupsDialog(user) {
      this.editedUser = user.uuid
      this.assignGroupsDialog = true
    },
    closeAssignGroupsDialog() {
      this.assignGroupsDialog = false
      this.editedUser = null
    },
    showSetPasswordDialog(user) {
      this.editedUser = user.uuid
      this.setPasswordDialog = true
    },
    closeSetPasswordDialog(user) {
      this.setPasswordDialog = false
      this.editedUser = null
    },
  },
}
</script>
