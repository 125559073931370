<template>
  <CenterLayout>
    <DefaultCard v-if="showLoginForm">
      <template #title>
        {{ title }}
      </template>
      <v-form v-if="config.login_form_enabled" data-testid="login-form" @submit.prevent="login">
        <v-card-text>
          <span v-if="state === 'invalid_credentials'" class="error--text body-1">
            {{ $t('invalidCredentials') }}
          </span>
          <span
            v-else-if="state === 'email_validation_hint'"
            class="error--text body-1"
            data-testid="email-validation-hint"
          >
            {{ emailValidationHintText }}
          </span>
          <span v-else-if="state === 'spnego_failed'" class="error--text body-1">
            {{ $t('singleSignOnFailed') }}
          </span>
          <v-text-field
            v-model="username"
            :error="hasErrors"
            name="username"
            :label="uidLabel"
            type="text"
            prepend-icon="fa-user"
            autofocus
          ></v-text-field>
          <BasePasswordField v-model="password" :error="hasErrors" />
          <v-checkbox v-model="rememberMe" :label="$t('stayLoggedIn')"></v-checkbox>
          <v-btn type="submit" color="primary" depressed block class="px-8">{{ $t('login') }}</v-btn>
        </v-card-text>
        <v-divider class="my-3" />
        <v-card-actions class="d-flex align-center flex-column">
          <router-link v-if="config.reset_password_enabled" :to="{ name: 'reset-password' }">{{
            $t('forgotPassword')
          }}</router-link>
          <router-link v-if="config.self_registration_enabled" :to="{ name: 'self-registration' }">{{
            $t('selfRegistrationLink')
          }}</router-link>
        </v-card-actions>
      </v-form>
      <ExternalLogin />
    </DefaultCard>

    <DefaultCard v-else-if="state === 'tfa_nodevice'">
      <template #title> <v-icon>fa-ban</v-icon> {{ $t('twoFactorAuthentication') }} </template>
      <v-card-text>
        <span>{{ $t('twoFactorNotSetup') }}</span>
      </v-card-text>
    </DefaultCard>

    <DefaultCard v-else-if="state === 'tfa_setup'">
      <template #title> <v-icon>fa-exclamation-triangle</v-icon> {{ $t('twoFactorAuthentication') }} </template>
      <v-form @submit.prevent="$router.push({ name: 'setup-totp' })">
        <v-card-text>
          <span>{{ $t('twoFactorSetup') }}</span>
        </v-card-text>
        <v-card-actions>
          <v-btn type="submit" depressed block color="primary">{{ $t('continue') }}</v-btn>
        </v-card-actions>
      </v-form>
    </DefaultCard>

    <DefaultCard v-else-if="state === 'tfa_required' || state === 'tfa_invalid'">
      <template #title>
        {{ $t('twoFactorAuthentication') }}
      </template>
      <v-form v-model="valid" @submit.prevent="verifyToken">
        <v-card-text>
          <p>{{ $t('tfaEntryPrompt.enterCodePrompt') }}</p>
          <p v-if="stateDetails.tfaDeviceTypes.includes('SMSDevice')">{{ $t('tfaEntryPrompt.codeFromSMSHelp') }}</p>
          <div v-else>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p v-html="$t('tfaEntryPrompt.codeFromTOTPAppHelp')"></p>
            <TOTPAppList :icons-only="true" />
            <br />
            <p>{{ $t('tfaEntryPrompt.codeFromTOTPAppSelectAccount') }}</p>
          </div>
          <span v-if="state === 'tfa_invalid'" class="error--text body-1">{{ $t('invalidCode') }}</span>
          <v-text-field
            ref="token"
            v-model="token"
            :error="hasErrors"
            name="token"
            label="Code"
            type="text"
            prepend-icon="fa-user-lock"
            autocomplete="off"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn type="submit" color="primary" block depressed>{{ $t('verify') }}</v-btn>
        </v-card-actions>
      </v-form>
    </DefaultCard>
  </CenterLayout>
</template>

<script>
import { branding } from '@/components/mixins'
import ExternalLogin from '@/components/ExternalLogin'
import DefaultCard from '@/components/DefaultCard'
import CenterLayout from '@/layouts/center'
import TOTPAppList from '@/components/TOTPAppList'
import api from '@/api'
import { mapActions } from 'vuex'
import { configureRoutes } from '@/router'
import BasePasswordField from '@/components/BasePasswordField'

export default {
  components: { ExternalLogin, DefaultCard, TOTPAppList, BasePasswordField, CenterLayout },
  mixins: [branding],
  props: {
    source: {
      type: String,
      default: () => '',
    },
  },
  data: () => ({
    drawer: null,
    username: null,
    password: '',
    rememberMe: false,
    token: null,
    valid: null,
    detail: null,
    state: 'login',
    hasErrors: false,
    emailValidationHintText: '',
  }),
  computed: {
    showLoginForm() {
      if (this.state === 'login' && this.config.spnego && this.$route.query.sso !== 'off') {
        return false
      } else if (
        this.state === 'email_validation_hint' ||
        this.state === 'login' ||
        this.state === 'invalid_credentials' ||
        this.state === 'spnego_failed'
      ) {
        return true
      }
      return false
    },
    uidLabel() {
      return this.config.username_is_email ? this.$t('email') : this.$t('username')
    },
  },
  watch: {
    state(val) {
      if (val === 'logged_in') {
        this.$router.push({ name: 'home' })
      } else if (val === 'tfa_required') {
        this.$nextTick(() => this.$refs.token.focus())
      }
    },
    username(val) {
      const regex = new RegExp(this.config.email_validation_hint_regex)
      const isMatch = regex.test(val)
      if (isMatch && this.config.email_validation_hint_text) {
        this.hasErrors = true
        this.state = 'email_validation_hint'
        this.emailValidationHintText = this.config.email_validation_hint_text
      } else {
        this.hasErrors = false
        this.state = 'login'
        this.emailValidationHintText = ''
      }
    },
  },
  mounted() {
    this.singleSignOn()
  },
  methods: {
    ...mapActions('config', ['fetchConfig']),
    login() {
      this.$store.commit('login/SET_NEXT', this.$route.query.next)
      api
        .post('/api/login', {
          username: this.username,
          password: this.password,
          remember_me: this.rememberMe,
          invitation: this.$route.query.invitation || '',
        })
        .then(async (res) => {
          this.state = res.data.state
          this.stateDetails = res.data.stateDetails
          const callback = res.data.invitation_callback
          this.hasErrors = false
          if (callback) {
            window.location = callback
          }
          const { data: config } = await this.fetchConfig()
          configureRoutes(config, this.$router)
        })
        .catch(() => {
          this.state = 'invalid_credentials'
          this.hasErrors = true
        })
    },
    verifyToken() {
      api
        .post('/api/login', { token: this.token.replace(/\s/g, '') })
        .then((res) => {
          this.state = res.data.state
          this.stateDetails = res.data.stateDetails
          this.hasErrors = false
        })
        .catch(() => {
          this.state = 'tfa_invalid'
          this.hasErrors = true
        })
    },
    singleSignOn() {
      if (this.config.spnego && this.$route.query.sso !== 'off') {
        this.$store.commit('login/SET_NEXT', this.$route.query.next)
        api
          .get('/api/spnego')
          .then((res) => {
            if (res.data.username) {
              this.$router.push({ name: 'home' })
            }
          })
          .catch(() => {
            this.state = 'spnego_failed'
          })
      }
    },
  },
}
</script>
