<template>
  <CenterLayout>
    <DefaultCard>
      <template #title>
        {{ $t('disableTwoFactorAuthentication') }}
      </template>
      <v-form @submit.prevent="disableTOTP">
        <v-card-text>
          <span>
            {{ $t('pleaseEnterPasswordToDisable2FA') }}
          </span>
          <BasePasswordField v-model="password" :error-messages="errorMessages" autofocus />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn depressed :to="{ name: 'account' }" color="accent">{{ $t('cancel') }}</v-btn>
          <v-btn depressed type="submit" color="primary">{{ $t('disable') }}</v-btn>
        </v-card-actions>
      </v-form>
    </DefaultCard>
  </CenterLayout>
</template>

<script>
import api from '@/api'
import DefaultCard from '@/components/DefaultCard'
import BasePasswordField from '@/components/BasePasswordField.vue'
import CenterLayout from '@/layouts/center'

export default {
  name: 'DisableTOTP',
  components: { DefaultCard, BasePasswordField, CenterLayout },
  data: () => ({
    password: '',
    errorMessages: [],
  }),
  methods: {
    disableTOTP() {
      api
        .delete('/api/totp', { data: { password: this.password } })
        .then(() => {
          this.$router.push({ name: 'home' })
        })
        .catch((error) => {
          if (error.response.data.detail === 'Wrong password') {
            this.errorMessages = [this.$t('wrongPassword')]
          } else if (error.response.status === 403) {
            this.errorMessages = [this.$t('disable2FAForbidden')]
          }
        })
    },
  },
}
</script>
