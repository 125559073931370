<template>
  <CenterLayout>
    <DefaultCard>
      <template #title>
        {{ $t('createAccount') }}
      </template>
      <v-card-text v-if="errors.nonfield">
        <span class="error--text body-1">{{ errors.nonfield }}</span>
      </v-card-text>
      <template v-if="isValidInvitation">
        <v-form @submit.prevent="signup">
          <v-card-text>
            <i18n path="loginWithExistingAccount" tag="p" class="body-2" data-testid="logged-in-message">
              <template v-slot:login>
                <router-link :to="{ name: 'login', query: { invitation } }">{{ $t('switchToLogin') }}</router-link>
              </template>
            </i18n>
            <v-text-field v-model="user.email" label="E-mail" autofocus :error-messages="errors.email" />
            <v-text-field v-model="user.first_name" :label="$t('firstName')" :error-messages="errors.first_name" />
            <v-text-field v-model="user.last_name" :label="$t('lastName')" :error-messages="errors.last_name" />
            <PasswordField v-model="user.password" :label="$t('password')" :user-information="user" />
          </v-card-text>
          <v-card-actions>
            <v-btn depressed block type="submit" color="primary" :loading="loading">{{ $t('signup') }}</v-btn>
          </v-card-actions>
        </v-form>
      </template>
    </DefaultCard>
  </CenterLayout>
</template>

<script>
import { branding } from '@/components/mixins'
import api from '@/api'
import DefaultCard from '@/components/DefaultCard'
import PasswordField from '@/components/PasswordField'
import CenterLayout from '@/layouts/center'

export default {
  components: { PasswordField, DefaultCard, CenterLayout },
  mixins: [branding],
  data() {
    return {
      invitation: null,
      invitationCallback: null,
      isValidInvitation: false,
      user: {
        email: '',
        first_name: '',
        last_name: '',
        password: '',
      },
      errors: {},
      loading: false,
    }
  },
  watch: {
    $route: {
      async handler({ query: { invitation = '' } }) {
        this.invitation = invitation
        try {
          const {
            data: { email, callback, isAuthenticated },
          } = await api.post('/api/registration/validate-invitation', { invitation })
          if (!this.config.invitation_enabled) {
            if (!isAuthenticated) {
              this.$router.push({ name: 'login', query: { next: callback } })
            } else {
              this.$store.commit('login/SET_NEXT', callback)
              this.$router.push({ name: 'home' })
            }
            return
          }
          if (this.config.external_authentication) {
            // If the invitation is valid and authentication is handled externally,
            // redirect to the external registration page.
            window.location = `${api.defaults.baseURL}/api/registration/external?invitation=${invitation}`
            return
          }
          this.user.email = email
          this.invitationCallback = callback
          this.isValidInvitation = true
        } catch (requestError) {
          if (requestError.response) {
            const {
              data: {
                invitation: { errors },
              },
            } = requestError.response
            this.errors = errors
          } else {
            console.log(requestError)
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    async signup() {
      this.loading = true
      try {
        const {
          data: {
            registration: { callback },
          },
        } = await api.post(`/api/registration/register?invitation=${this.invitation}`, {
          email: this.user.email,
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          password: this.user.password,
        })
        window.location = callback
      } catch ({
        response: {
          data: {
            registration: { errors },
          },
        },
      }) {
        this.errors = errors
        this.loading = false
        this.isValidInvitation = true
      }
    },
  },
}
</script>
