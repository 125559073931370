<template>
  <v-form ref="form">
    <v-card :loading="inquiryLoading">
      <v-card-title>{{ $t('deleteUser') }}</v-card-title>
      <v-card-text>
        <i18n path="deleteUserConfirm" tag="p" class="body-2">
          <template v-slot:user>
            <b>{{ `${user.first_name} ${user.last_name} (${user.email})` }}</b>
          </template>
        </i18n>
        <p v-if="inquiryRejectReason" class="red--text">
          {{ inquiryRejectReason }}
        </p>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" depressed @click="close">{{ $t('cancel') }}</v-btn>
        <v-btn color="primary" :disabled="deleteDisabled" depressed @click="deleteUser">{{ $t('delete') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import api from '@/api'

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      inquiryRejectReason: '',
      inquiryLoading: false,
    }
  },
  computed: {
    deleteDisabled() {
      return this.inquiryLoading || !!this.inquiryRejectReason
    },
  },
  watch: {
    user: {
      handler(user) {
        if (user.uuid && this.config.has_web_hooks) {
          this.inquireDeletion(user)
        }
      },
      immediate: true,
    },
  },
  methods: {
    async inquireDeletion(user) {
      this.inquiryLoading = true
      this.inquiryRejectReason = ''
      try {
        await api.post('/api/users/inquire-delete', { uuid: user.uuid })
      } catch (error) {
        if (error.response.status === 412) {
          this.inquiryRejectReason = error.response.data.reason
        } else {
          this.inquiryRejectReason = this.$t('inquiryNotPossible')
          throw error
        }
      } finally {
        this.inquiryLoading = false
      }
    },
    async deleteUser() {
      try {
        await api.delete(`/api/users/${this.user.uuid}`)
        this.$emit('close-dialog')
        this.$emit('reload-users')
      } catch (error) {
        console.log(error.response)
      }
    },
    close() {
      this.$emit('close-dialog')
    },
  },
}
</script>
