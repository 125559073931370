<template>
  <CenterLayout>
    <DefaultCard>
      <template #title>
        {{ $t('selfRegistrationTitle') }}
      </template>
      <template v-if="!success">
        <v-form @submit.prevent="submit">
          <v-card-text>
            <p>{{ $t('selfRegistrationText') }}</p>
            <p v-if="config.privacy_policy" v-dompurify-html="config.privacy_policy" />
            <v-text-field
              ref="email"
              v-model="form.email"
              label="E-Mail Adresse"
              autofocus
              :error-messages="$t(errors.email)"
              @input="clearErrors"
            />
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn depressed :to="{ name: 'login' }">{{ $t('cancel') }}</v-btn>
            <v-btn depressed type="submit" color="primary" min-width="120" :loading="loading" :disabled="!isCompleted">
              {{ $t('selfRegistrationRegister') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </template>
      <template v-if="success">
        <v-card-text>
          <i18n path="selfRegistrationEmailSent" tag="p" class="body-1" data-testid="logged-in-message">
            <template v-slot:email>
              <b>{{ form.email }}</b>
            </template>
          </i18n>
        </v-card-text>
      </template>
    </DefaultCard>
  </CenterLayout>
</template>

<script>
import api from '@/api'
import { branding } from '@/components/mixins'
import DefaultCard from '@/components/DefaultCard'
import CenterLayout from '@/layouts/center'

export default {
  name: 'SelfRegistration',
  components: { DefaultCard, CenterLayout },
  mixins: [branding],
  data() {
    return {
      form: {
        email: '',
      },
      valid: false,
      loading: false,
      success: false,
      errors: {},
    }
  },
  computed: {
    isCompleted() {
      return this.form.email.includes('@')
    },
  },
  mounted() {
    this.$refs.email.focus()
  },
  methods: {
    async submit() {
      this.loading = true
      try {
        await api.post('/api/registration/self-registration', this.form)
        this.success = true
      } catch ({ response: { data: errors } }) {
        this.errors = errors.errors
      }
      this.loading = false
    },
    clearErrors() {
      this.errors = {}
    },
  },
}
</script>
