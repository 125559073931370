<template>
  <v-form ref="form" v-model="valid" @submit.prevent="add">
    <v-card>
      <v-card-title>{{ $t('addGroup') }}</v-card-title>
      <v-card-text>
        <v-container class="pa-0">
          <v-row>
            <v-col v-if="errorMessages.non_field_errors" cols="12">
              <span class="error--text body-1">{{ $t('anErrorOccurred') }} {{ errorMessages.non_field_errors }}</span>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="group.cn"
                :label="$t('groupId')"
                :rules="[rules.required, rules.validChars]"
                :loading="loading"
                :error-messages="errorMessages.cn"
                dense
                autofocus
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="group.description"
                :label="$t('groupName')"
                :loading="loading"
                :error-messages="errorMessages.description"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" depressed @click="close">{{ $t('cancel') }}</v-btn>
        <v-btn :disabled="!valid" type="submit" color="primary" depressed>{{ $t('add') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import api from '@/api'

export default {
  data() {
    return {
      loading: false,
      valid: true,
      group: {},
      errorMessages: {},
      rules: {
        required: (value) => !!value || this.$t('inputRequired'),
        validChars: (value) => {
          const pattern = /^[a-zA-Z0-9][a-zA-Z0-9 _-]*$/
          return pattern.test(value) || this.$t('containsInvalidChars')
        },
      },
    }
  },
  methods: {
    close() {
      this.errorMessages = {}
      this.group = {}
      this.$refs.form.reset()
      this.$emit('close-dialog')
    },
    async add() {
      if (!this.$refs.form.validate()) return
      const data = {
        cn: this.group.cn,
        description: this.group.description || null,
      }
      try {
        await api.post('/api/groups/', data)
        this.groups = {}
        this.$refs.form.reset()
        this.$emit('close-dialog')
        this.$emit('reload-groups')
      } catch (error) {
        if (error) {
          if (error.response.status === 400) {
            this.errorMessages = error.response.data
          } else if (error.response) {
            this.errorMessages = { non_field_errors: error.response.statusText }
          }
        }
      }
    },
  },
}
</script>
