<template>
  <DefaultLayout :info="info">
    <v-data-table :headers="tableHeaders" :items="sessions" hide-default-footer disable-sort>
      <template #item.browser="{ item: session }">
        <v-icon small>{{ session.icon }}</v-icon
        >&nbsp;{{ session.browser }}, {{ session.os }}
      </template>
      <template #item.end_session="{ item: session }">
        <v-btn v-if="!session.current" depressed color="accent" small @click="endSession(session.id)">{{
          $t('endSession')
        }}</v-btn>
        <span v-else>{{ $t('currentSession') }}</span>
      </template>
    </v-data-table>
  </DefaultLayout>
</template>
<script>
import DefaultLayout from '@/layouts/default'
import api from '@/api'
import { loginRequired } from '@/components/mixins'
import UAParser from 'ua-parser-js'
import { DateTime } from 'luxon'

// UBit OneGov Connector (Windows NT 10.0; Win64; x64) OneGovSKSK/3.90.2014.7
const ubitBrowserRegex = [
  [/ubit onegov connector.*OneGov[^/]*([\w.]+)/i],
  [[UAParser.BROWSER.NAME, 'GEVER Laufwerk'], UAParser.BROWSER.VERSION],
]

const browserIcons = {
  Chrome: 'fab fa-chrome',
  Edge: 'fab fa-edge',
  Firefox: 'fab fa-firefox',
  IE: 'fab fa-internet-explorer',
  IEMobile: 'fab fa-internet-explorer',
  'Mobile Safari': 'fab fa-safari',
  Safari: 'fab fa-safari',
  Opera: 'fab fa-opera',
}

export default {
  components: { DefaultLayout },
  mixins: [loginRequired],
  data() {
    return {
      tableHeaders: [
        { text: 'IP', value: 'ip_address' },
        { text: 'Browser', value: 'browser' },
        { text: this.$t('recentActivity'), value: 'since' },
        { text: '', value: 'end_session' },
      ],
    }
  },
  computed: {
    sessions() {
      return this.info.sessions.map((session) => {
        const parser = UAParser(session.user_agent, { browser: ubitBrowserRegex })
        return {
          ...session,
          since: DateTime.fromISO(session.modified).toRelative(),
          browser: parser.browser.name,
          icon: browserIcons[parser.browser.name] || 'fa-dot-circle',
          os: `${parser.os.name} ${parser.os.version}`,
        }
      })
    },
  },
  methods: {
    endSession(id) {
      api.post('/api/logout', { session_id: id }).then(() => this.fetchInfo())
    },
  },
}
</script>
